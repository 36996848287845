
  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .container {
    height: 280px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 1rem;
    pointer-events: none;
  }
  
  .card {
    width: 240px;
    border-radius: 0.75rem;
    background-size: cover;
    background-color: #04abe2;
    cursor: pointer;
    overflow: hidden;
    border-radius: 1rem;
    transition: 0.6s cubic-bezier(0.28, -0.03, 0, 0.99);
    pointer-events:all;
  }
  
  .card:hover {
    width: 400px;
  }
  
  .container:hover .card:not(:hover) {
    width: 180px;
  }
  
  .image-container {
    transition: all 0.5s ease;
  }
  
  .description-container {
    display: none;
    transition: all 0.5s ease;
  }
  
  .card:hover .description-container {
    display: flex;
  }
  
  .card:hover .title-container {
    display: none;
  }
  
  .container:hover .card:not(:hover) .title-container {
    display: none;
  }
  
  @media (max-width: 500px){
    .container {
        height: 100%;
        display: flex;
        padding: 0 20px;
        flex-wrap: nowrap;
        justify-content: center;
        gap: 1rem;
        pointer-events: none;
      }
      
      .card {
        width: 100%;
        height: 300px;
        border-radius: 0.75rem;
        background-size: cover;
        background-color: #04abe2;
        cursor: pointer;
        overflow: hidden;
        border-radius: 1rem;
        transition: 0.6s cubic-bezier(0.28, -0.03, 0, 0.99);
        pointer-events:all;
        pointer-events: none;
        user-select: none;
      }

      .card:hover {
        width: 100%;
        height: 200px;
      }

      .container:hover .card:not(:hover) {
        width: 100%;
      }
      
  }

  @media (max-width: 768px){
    .container {
        height: 100%;
        display: flex;
        padding: 0 20px;
        flex-wrap: nowrap;
        justify-content: center;
        gap: 1rem;
        pointer-events: none;
      }
      
      .card {
        width: 350px;
        height: 300px;
        border-radius: 0.75rem;
        background-size: cover;
        background-color: #04abe2;
        cursor: pointer;
        overflow: hidden;
        border-radius: 1rem;
        transition: 0.6s cubic-bezier(0.28, -0.03, 0, 0.99);
        pointer-events:all;
        pointer-events: none;
        user-select: none;
      }

      .card:hover {
        width: 100%;
        height: 200px;
      }

      .container:hover .card:not(:hover) {
        width: 100%;
      }
      
  }